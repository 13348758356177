import Main from '@/app/components/Home'
import { GetServerSideProps } from 'next'
import Head from 'next/head'
import { HomeProps } from '@/app/interface/pages'
import { fetchBanners } from '@/app/libs/getData'

export default function HomePage({ setIsConditionsModalOpen, setConditionsContent, bannerImages, bannerImagesMobile, bannerImagesTablet, calls, links }: HomeProps) {
  return (
    <>
      <Head>
        <title>Azul Agro - Máquinas e Equipamentos Agrícolas de Alta Performance</title>
        <meta name="description" content="Descubra a Azul Agro, líder em máquinas e equipamentos agrícolas. Oferecemos soluções de alta performance para otimizar suas operações no campo. Conheça nossos produtos e impulsione sua produtividade!" />
        <link rel="preload" as="image" href={"https://gabcomercio.azureedge.net/azul-agro/desktop/home/introSection.webp"} media="(min-width: 1140px)" />
        <link rel="preload" as="image" href={"https://gabcomercio.azureedge.net/azul-agro/tablet/home/introSection.webp"} media="(min-width: 540px) and (max-width: 1139px)" />
        <link rel="preload" as="image" href={"https://gabcomercio.azureedge.net/azul-agro/mobile/home/introSection.webp"} media="(max-width: 539px)" />
      </Head>

      <Main
        bannerImages={bannerImages}
        bannerImagesMobile={bannerImagesMobile}
        bannerImagesTablet={bannerImagesTablet}
        calls={calls}
        links={links}
        setIsConditionsModalOpen={setIsConditionsModalOpen}
        setConditionsContent={setConditionsContent}
      />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    const [banners] = await Promise.all([fetchBanners()])

    // Filtra as chamadas que contêm '/'
    const filteredIndexes = banners.calls
      .map((call: string, index: number) => call === '/' ? index : -1)
      .filter((index: number) => index !== -1)

    // Mapeia os arrays mantendo o tamanho original, incluindo entradas vazias
    const filteredContents = filteredIndexes.map(index => banners.contents[index] || null)
    const filteredDefenseTypes = filteredIndexes.map(index => banners.defenseTypes[index] || null)
    const filteredBannerImages = filteredIndexes.map(index => banners.bannerImages[index] || null)
    const filteredBannerImagesTablet = filteredIndexes.map(index => banners.bannerImagesTablet[index] || null)
    const filteredBannerImagesMobile = filteredIndexes.map(index => banners.bannerImagesMobile[index] || null)

    // Filtra os links apenas se a imagem do banner existir
    const filteredLinks = filteredIndexes.map(index => 
      (banners.bannerImages[index] ? banners.links[index] : null)
    ).filter(link => link !== null)

    return {
      props: {
        contents: filteredContents,
        defenseTypes: filteredDefenseTypes,
        bannerImages: filteredBannerImages,
        bannerImagesTablet: filteredBannerImagesTablet,
        bannerImagesMobile: filteredBannerImagesMobile,
        calls: banners.calls || [],
        links: filteredLinks || []
      }
    }

  } catch (error) {
    console.error('Erro ao buscar metadados ou banners:', error)
    return {
      props: {
        contents: [],
        defenseTypes: [],
        bannerImages: [],
        bannerImagesTablet: [],
        bannerImagesMobile: [],
        calls: [],
        links: []
      }
    }
  }
}
