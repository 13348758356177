import React from "react"
import IntroSectionDesktop from "./IntroSectionDesktop"
import IntroSectionMobile from "./IntroSectionMobile"
import { useMediaQuery } from "react-responsive"
import { IntroSectionProps } from "@/app/interface/components"

const IntroSection: React.FC<IntroSectionProps> = ({ title, text, img, alignItems="flex-start", justifyContent="flex-start", lineInverted=false, paddingLeft=16, textAlign="start", paddingRight=16 }) => {
    const isDesktop = useMediaQuery({ minWidth: 1140 })
    
    return (
        <>
            {
                isDesktop ? (
                    <IntroSectionDesktop title={title} text={text} img={img} alignItems={alignItems} justifyContent={justifyContent} lineInverted={lineInverted} textAlign={textAlign} />
                ) : (
                    <IntroSectionMobile title={title} text={text} img={img} alignItems={alignItems} justifyContent={justifyContent} lineInverted={lineInverted} paddingLeft={paddingLeft} textAlign={textAlign} paddingRight={paddingRight} />
                )
            }
        </>
    )
}

export default React.memo(IntroSection)