import { InfoWithImageSectionProps } from "@/app/interface/components"
import dynamic from "next/dynamic"
import React, { useMemo } from "react"
import IntroSection from "../../UI/IntroSection"
import MediaQuery from "react-responsive"
import { HomeProps } from "@/app/interface/components"
import BannerSection from "./BannerSection"

const DynamicInfoWithImageSection = dynamic<InfoWithImageSectionProps>(() => import('@/app/components/UI/InfoWithImageSection'), { ssr: false })
const DynamicLocationSection = dynamic(() => import('./LocationSection'), { ssr: false })

const HomeMobile: React.FC<HomeProps> = ({ setIsConditionsModalOpen, setConditionsContent, bannerImagesMobile, bannerImagesTablet, calls, links }) => {
    const isTablet = MediaQuery({ minWidth: 540 })
    const srcImg = useMemo(() => `https://gabcomercio.azureedge.net/azul-agro/${isTablet ? 'tablet' : 'mobile'}/home/introSection.webp`, [isTablet])

    return (
        <main>
            {(bannerImagesMobile && bannerImagesMobile?.length > 0) && <BannerSection
                bannerImagesMobile={bannerImagesMobile}
                bannerImagesTablet={bannerImagesTablet}
                calls={calls}
                links={links}
                setIsConditionsModalOpen={setIsConditionsModalOpen}
                setConditionsContent={setConditionsContent}
            />}
            <IntroSection textAlign="end" title="Tecnologias de última geração" text="As melhores ferramentas para o seu agronegócio" img={srcImg} alignItems="flex-end" lineInverted paddingLeft={64} />
            <DynamicInfoWithImageSection title="Compromisso com o seu negócio" text="A Azul Agro, junto com a New Holland, apresenta as melhores máquinas e ferramentas dedicadas ao serviço de melhorar e inovar com o seu negócio. Oferecemos o melhor maquinário para atender as suas necessidades no campo, com qualidade, versatilidade e cuidado que só a Azul Agro pode oferecer." img="https://gabcomercio.azureedge.net/azul-agro/general/home/img1.webp" flexDirection="column" loading="lazy" width={530} height={600} />
            <DynamicInfoWithImageSection hasButton title="Soluções ideais, agilidade e inovação" text="A Azul Agro, empresa do Grupo Águia Branca, alia sua tradição de 77 anos de história e mais de 17 mil colaboradores, à expertise da New Holland Agriculture, líder global em equipamentos agrícolas há mais de 128 anos e reconhecida por suas soluções inovadoras com tecnologia de ponta para atender os produtores rurais." img="https://gabcomercio.azureedge.net/azul-agro/general/home/img2.webp" flexDirection="column" loading="lazy" width={530} height={600} />
            <DynamicLocationSection />
        </main>
    )
}

export default React.memo(HomeMobile)
