import { useMediaQuery } from "react-responsive"
import React, { useEffect, useState } from "react"
import HomeDesktop from "./HomeDesktop"
import HomeMobile from "./HomeMobile"
import { MainProps } from "@/app/interface/components"

const Main: React.FC<MainProps> = ({ setIsConditionsModalOpen, setConditionsContent, bannerImages, bannerImagesTablet, bannerImagesMobile, links, calls }) => {
    const isDesktop = useMediaQuery({ minWidth: 1140 })
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    // Renderiza nulo até que o componente esteja montado para evitar problemas de hidratação
    if (!isMounted) {
        return null
    }

    return (
        <>
            { isDesktop ? (
                <HomeDesktop
                    bannerImages={bannerImages}
                    calls={calls}
                    links={links}
                    setIsConditionsModalOpen={setIsConditionsModalOpen}
                    setConditionsContent={setConditionsContent}
                />
             ) : (
                <HomeMobile
                    bannerImagesMobile={bannerImagesMobile}
                    bannerImagesTablet={bannerImagesTablet}
                    calls={calls}
                    links={links}
                    setIsConditionsModalOpen={setIsConditionsModalOpen}
                    setConditionsContent={setConditionsContent}
                /> 
            )}
        </>
    )
}

export default React.memo(Main)
