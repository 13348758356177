import React from "react"
import styles from "@/styles/mobile/IntroSection.module.scss"
import { IntroSectionProps } from "@/app/interface/components"
import ImageComponent from "../ImageComponent"

const IntroSectionMobile: React.FC<IntroSectionProps> = ({ title, text, img, alignItems, justifyContent, lineInverted, paddingLeft, textAlign, paddingRight }) => {
    return (
        <section className={styles.main}>
            <div className={styles.content} style={{alignItems: alignItems, justifyContent: justifyContent, textAlign: textAlign }}>
                <img src="https://gabcomercio.azureedge.net/azul-agro/mobile/lineIntro.svg" alt="" width={196} height={8} style={lineInverted ? {transform: "scaleX(-1)"} : undefined} />
                <div style={{alignItems: alignItems, paddingLeft: paddingLeft, paddingRight: paddingRight}}>
                    <h1>{title}</h1>
                    {text && <h2 className="h2-regular">{text}</h2>}
                </div>
            </div>
            <ImageComponent
                src={img}
                width={430}
                height={741}
            />
        </section>
    )
}

export default React.memo(IntroSectionMobile)