import React from "react"
import styles from "@/styles/desktop/IntroSection.module.scss"
import { IntroSectionProps } from "@/app/interface/components"
import ImageComponentHeightFixed from "../ImageComponentHeightFixed"

const IntroSectionDesktop: React.FC<IntroSectionProps> = ({ title, text, img, alignItems, justifyContent, lineInverted, textAlign }) => {
    return (
        <section className={styles.main}>
            <div className={styles.content} style={{alignItems: alignItems, justifyContent: justifyContent, textAlign: textAlign }}>
                <ImageComponentHeightFixed src="https://gabcomercio.azureedge.net/azul-agro/desktop/lineIntro.svg" width={667} height={8} style={lineInverted ? {transform: "scaleX(-1)"} : undefined} />
                <div style={{alignItems: alignItems}}>
                    <h1>{title}</h1>
                    {text && <h2 className="h2-regular">{text}</h2>}
                </div>
            </div>
            <ImageComponentHeightFixed
                src={img}
                width={1920}
                height={800}
            />
        </section>
    )
}

export default React.memo(IntroSectionDesktop)